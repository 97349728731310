<template>
    <div class="row">
        <hr class="my-4" />
        <slot name="title">
            <div v-if="title" class="title-container">
                <div>
                    <h5 class="title">{{ title }}</h5>
                    <p v-if="description" class="description">{{ description }}</p>
                </div>
            </div>
        </slot>
        <div class="col-md-12">
            <ui-select
                id="activationStatus"
                name="activationStatus"
                label="Status"
                class="w-100 mb-4"
                option-label="label"
                option-value="value"
                @change="clearMessage"
                :options="status"
                :field="v$.formData.status"
                :disabled="!CAN(['write_activations'])"
                v-model:value="formData.status"

            />  
        </div>
        <div v-if="(formData.status == 'rejected' || formData.status == 'incomplete') && CAN(['write_activations'])" class="col-md-12">
            <ui-text-area
                id="comments"
                name="comments"
                :label="'Add comments'"
                v-model:value="formData.message"
                :field="v$.formData.message"
            />
        </div>
    </div>
    <div class="buttom-container">
    <div class="text-start">
        <ui-button
            v-if="this.form.status === 'incomplete' && this.typeForm === 'contract' && this.form.uri"
            type="submit"
            variant="secondary"
            :disabled="!CAN(['write_activations'])"
            :loading="loadingContract"
            @click="$emit('createContract')"
            >
                Create new contract
        </ui-button>
    </div>
    <div class="text-end">
        <ui-button
            :disabled="!CAN(['write_activations'])"
            :loading="loading"
            type="submit"
            variant="primary"
            @click="sendReview"
        >
            Send review
        </ui-button>
    </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { filters as data_filters } from '@/data';
import UiTextArea from '@/components/ui/TextArea';
import UiSelect from '@/components/ui/Select';
import UiButton from '@/components/ui/buttons/Button';

export default {
    name: 'formStatus',
    components: { UiSelect, UiTextArea, UiButton },
    props: {
        title: {
            type: String,
            default: null,
            required: false,
        },
        description: {
            type: String,
            default: null,
            required: false,
        },
        form: {
            type: Object,
            default: null,
            required: false,
        },
        typeForm: {
            type: String,
            default: null,
            required: false,
        },
        loadingContract: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    emits: ['updateStatus', 'createContract'],
    data() {
        return {
            loading: false,
            formData: {
                status: this.form.status,
                message: this.form.message,
            },
            newData: {},
            statusContract: [
            {
                label: 'Rejected',
                value: 'rejected',
            },
            {
                label: 'Incomplete',
                value: 'incomplete',
            },
            {
                label: 'Accepted',
                value: 'accepted',
            },
        ],
        time_line: [],
        }
    },
    computed: {
        status() {
            return this.typeForm == 'contract' ? this.statusContract : data_filters.activation.status;
        }
    },
    watch: {
        form: {
            handler(new_options) {
            if (this.typeForm === 'contract') {
                if (new_options.status === 'accepted') {
                    this.formData = {
                        status: new_options.status,
                        message: '',
                    };
                } else if (this.form.status === 'incomplete' || this.form.status === 'rejected') {
                    this.formData = {
                        status: new_options.status,
                        message: new_options.message,
                    };
                } else {
                    this.formData = {
                        status: null,
                        message: '',
                    };
                }
            } else {
                this.formData = {
                status: new_options.status,
                message: new_options.message,
                };
            }
            },
            immediate: true,
        },
        },
    validations() {
        return {
            formData: {
                message: {
                    required: helpers.withMessage('The field message is required.', required),
                },
                status: {
                    required: helpers.withMessage('The field status is required.', required),
                },
            },
        };
    },
    methods: {
        clearMessage() {
            this.formData.message = '';
        },
        async sendReview() {
            try {
                if( this.formData.status == 'rejected' || this.formData.status == 'incomplete' || this.formData.status == null ){
                    const validated = await this.v$.$validate();
                    if (!validated) return
                }
                this.loading = true;
                const { status, message } = this.formData;
                let data = {
                    [this.typeForm]: {
                        status,
                        ...(message ? { message } : {}),
                    },
                }
                this.newData = this.typeForm === 'documents' || this.typeForm === 'business' || this.typeForm === 'individual' ? { verification: data } : data;
                
                const res = await this.$axios.put(`/_/activations/${this.$route.query.id}`, this.newData);
                this.time_line = res.data.timeline;    
                this.$store.commit('activations/updateGlobalStatus', data);
                this.$store.commit('activations/updateTimeLine', this.time_line);
                this.$toast.success('Update status succesfully.');
                this.$emit('updateStatus', res.data);
                this.v$.$reset();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        }
    },
}
</script>

<style lang="scss">
    .title-container {
        display: flex;
        justify-content: space-between;
    }

    .title {
        font-weight: 600;
    }

    .buttom-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .description {
        color: $ecart-secondary-500;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 2rem;
    }
</style>