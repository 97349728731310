<template>
    <div class="row align-items-center">
        <template v-for="(field, index) in informationOptions">
            <div v-if="field.mime_type" :key="index" class="col-md-6 mb-1 py-4">
                <ui-files-item :file-name="getlabel(field.id)" :file-link="field.value" :file-type="field.mime_type"/>
            </div>
        </template>
        <div v-if="!hasFiles">
            <div class="documents__no-files-found">
                <empty-state text="Documents no found" img="folder.svg" img-size="md"/>
            </div>
        </div>
    </div>
    <status-form v-if="hasFiles && status !== 'accepted'" title="Review documents" description="You need to documents details of your account." :form="verification" type-form="documents"/>
</template>

<script>
import StatusForm from '@/components/activations/StatusForm';
import UiFilesItem from '@/components/ui/files/FilesItem';
import EmptyState from '@/components/ui/EmptyState';

export default {
    name: 'DocumentsForm',
    components: { StatusForm, UiFilesItem, EmptyState },
    props: {
        verification: {
            type: Object,
            default: () => {},
            required: true,
        },
        status: {
            type: String,
            default: null,
            required: true,
        }
    },
    data() {
        return {
            hasFiles: false
        };
    },
    computed: {
        informationOptions() {
            return this.verification.information || [];
        },
    },
    watch: {
        informationOptions: {
            handler(newOptions) {
                this.hasFiles = newOptions.some((field) => field.mime_type);
            },
            immediate: true,
        },
    },
    methods: {
        getlabel(fieldName) {
            return fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/_/g, ' ');
        },
    },
}
</script>
<style lang="scss">
.documents{
    &__no-files-found {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;    
    }
}
</style>