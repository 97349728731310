<template>
    <div class="row align-items-center" v-if="informationOptions.length > 0">
        <div class="col-md-12">
            <p class="legal-entity__subtitle">
                <span class="fas fa-building"></span>
                Company information
            </p>
        </div>
        <template v-for="(field, index)  in informationOptions">
            <div v-if="shouldShowField(field.id)" :key="index" class="col-md-6 mb-4">
                <ui-input
                    :id="field.id"
                    :name="field.id"
                    :label="getlabel(field.id)"
                    type="text"
                    :value="field.value"
                    :disabled="isDisabled(field.id)"
                />
            </div>
        </template>
        <div class="col-md-12">
            <p class="legal-entity__subtitle">
                <span class="fas fa-file"></span>
                Documents
            </p>
        </div>
        <template v-for="(field, index) in informationOptions">
            <div v-if="field.mime_type" :key="index" class="col-md-6 mb-1">
                <ui-files-item :file-name="getlabel(field.id)" :file-link="field.value" :file-type="field.mime_type"/>
            </div>
        </template>
        <div v-if="!hasFiles">
            <div class="legal-entity__no-files-found">
                <empty-state title="Documents no found..." img="folder.svg" imgSize="md"/>
            </div>
        </div>
    </div>
    <div class="py-4" v-else>
        <empty-state title="No legal entity found..."/>
    </div>
    <status-form v-if="hasFiles && status !== 'accepted'" title="Review data legal entity" description="You need to legal entity details of your account." :form="verification" type-form="business"/>
</template>

<script>
import StatusForm from '@/components/activations/StatusForm';
import UiInput from '@/components/ui/Input.vue';
import UiFilesItem from '@/components/ui/files/FilesItem';
import EmptyState from '@/components/ui/EmptyState';

export default {
    name: 'LegalEntityForm',
    components: { StatusForm, UiInput, UiFilesItem, EmptyState },
    props: {
        verification: {
            type: Object,
            default: () => {},
            required: true,
        },
        status: {
            type: String,
            default: null,
            required: true,
        },
        disabledInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    data() {
        return {
            hasFiles: false
        };
    },
    computed: {
        informationOptions() {
            return this.verification.information || [];
        },
    },
    watch: {
        informationOptions: {
            handler(newOptions) {
                this.hasFiles = newOptions.some((field) => field.mime_type);
            },
            immediate: true,
        },
    },

    methods: {
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        shouldShowField(fieldName) {
            const fieldsToShow = [
                'company_name', 
                'street', 
                'external_number', 
                'district', 'state', 
                'postal_code', 'country', 
                'locale', 
                'constitution_date',
                'tax_identification',
                'business_activity',
            ];
            return fieldsToShow.includes(fieldName);
        },
        getlabel(fieldName) {
            return fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/_/g, ' ');
        },
    },
}
</script>

<style lang="scss">
.legal-entity{
    &__subtitle {
        color: $ecart-secondary-500;
        margin-bottom: 16px;
    }
        
    &__no-files-found {
        text-align: center;
        padding: 10% 0;
    }
}
</style>