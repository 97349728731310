<template>    
    <div v-if="verification.provider" class="text-center">
        <div class="py-4">
            <empty-state text="Check verification in sumsub" img="natural-person.svg" img-size="lg" :show-button="true" :url="verification.provider.admin_provider_url" @redirect="handleRedirect" />
        </div>
    </div>
    <div class="py-4" v-else>
        <empty-state title="No verification found..."/>
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';

export default {
    name: 'IndividualForm',
    components: { EmptyState },
    props: {
        verification: {
            type: Object,
            default: () => {},
            required: true,
        },
        typeVerificationSumsub: {
            type: String,
            default: () => {},
            required: true,
        }
    },
    methods: {
        handleRedirect(url) {
            window.open(url, '_blank');
        },
    }
}
</script>