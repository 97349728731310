<template>
    <div class="user-info">
        <template v-if="info">
            <ui-list>
                <ui-list-item title="Name:">
                    <template #text>
                        {{ info.first_name }} {{ info.last_name || '' }}
                    </template>
                </ui-list-item>
                <ui-list-item v-if="info.paylink_alias" title="Payment link alias:" :text="info.paylink_alias" />
                <ui-list-item title="Country:" :text="info.country" />
                <ui-list-item title="Email:">
                    <template #text>
                        <ui-link
                            v-if="info.email"
                            weight="medium"
                            :link="`mailto:${info.email}`"
                        >
                            {{ info.email }}
                        </ui-link>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Phone:">
                    <template #text>
                        <ui-link
                            v-if="info.phone"
                            weight="medium"
                            :link="`tel:+${info.phone}`"
                        >
                            {{ info.phone }}
                        </ui-link>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </template>
                </ui-list-item>
                <ui-list-item title="Contact:">
                    <template #text>
                        <contact-links :phone="info.phone || null" :email="info.email || null" />
                    </template>
                </ui-list-item>
            </ui-list>
        </template>
    </div>
</template>

<script>

import ContactLinks from '@/components/ui/ContactLinks';
import UiBadge from '@/components/ui/Badge';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    name: 'GeneralInformation',
    components: {
        ContactLinks,
        UiBadge,
        UiLink,
        UiList,
        UiListItem
    },
    props: {
        info: {
            type: Object,
            default: () => {}
        },
    },
};
</script>
