<template>
    <ui-modal
        ref="rejectActivationModal"
        class="reject-activation-modal"
        title="Reject activation"  
        centered
        @hidden="clearForm"
    >
    <div class="reject-activation-modal__content">
            <div class="row" v-if="activationID">
                <p class="activation-info__item-text">
                    <span class="fas fa-exclamation-circle"></span>
                    Are you sure you should mark this activation as rejected?
                </p>
                <ui-text-area
                        id="description"
                        name="description"
                        class="mb-4"
                        label="Enter the reason..."
                        v-model:value="description"
                        :field="v$.description"
                />
            </div>
    </div>
        <template #footer>
            <ui-button variant="light" @click="hide">
                Close
            </ui-button>
            <ui-button
            :loading="loading" 
            variant="danger" @click="accept">
                Reject
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import UiButton from '@/components/ui/buttons/Button';
import UiModal from '@/components/ui/Modal';
import UiTextArea from '@/components/ui/TextArea';

export default {
    name: 'RejectModal',  
    components: {
        UiButton,
        UiModal,
        UiTextArea
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            api: null,
            loading: false,
            activationID: null,
            description: '',
        };
    },
    validations () {
        return {
            description: {
                required,
                minLength: helpers.withMessage('Description is too short', value => value.length > 30),
                $autoDirty: true,
            }, 
        }
    },
    mounted() {
        this.modal = this.$refs.rejectActivationModal;
    },
    methods: {
        show(activationID) {
            this.activationID = activationID;
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        },
        async accept() {
            const validated = await this.v$.$validate();
            if (!validated) return
            try {
                this.loading = true;
                const payLoad = {
                status: 'rejected',
                message: this.description,
            };
            const {data} = await this.$axios.put(`/_/activations/${this.activationID}`, payLoad);
            this.$emit('rejectCompleted', data)
            this.$toast.success('Update rejected status succesfully.');
            this.modal.hide();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }finally {
                this.loading = false;
            }
            
        },
        clearForm() {
            this.description = '';
            this.v$.$reset();
        },
    },
};
</script>

<style lang="scss">
.reject-activation-modal {
    &__card-title {
        color: $general-black;
        font-size: 1.05rem;
        margin-bottom: 0;
    }

    &__item-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        margin-bottom: 3px;
    }

    &__item-text {
        color: $ecart-secondary-300;
        font-weight: 500;
        margin-bottom: 0;
    }
}
.fa-exclamation-circle {
    color: $ecart-secondary-500;
}
</style>