<template>
    <div>
        <div v-if="contractData && contractData.uri != ''">
            <ui-files-item v-if="contractData.status !== 'sent' && contractData.status !== 'pending'" :file-link="contractUrl" :file-name="'Contract'" :icon-view="'fa-eye'" :download="viewDocument" file-type="link"/>
            <empty-state v-if="contractData.status === 'sent'" text="A draft of the contract has been prepared, it is necessary to wait for the compliance department to review and send the contract to the customer." img="sent-contract.svg" img-size="md"/>
            <empty-state v-else-if="contractData.status === 'pending'" text="The contract has been sent to the client, it is necessary to wait for his signature." img="pending-contract.svg" img-size="md"/>
            <status-form v-else-if="CAN(['write_activations'])" @updateStatus="updateContrat" @createContract="createContract" title="Review file contract" description="You need to contract details of your account." :form="contractData" :loading-contract="loading" type-form="contract"/>
        </div>
        <div v-else class="contract__no-files-found">
            <empty-state v-if="allAcepted" text="The contract can be created." img="empty-contract.svg" img-size="md"/>
            <empty-state v-else text="The contract can be created when all user information is accepted." img="empty-contract.svg" img-size="md"/>
            <ui-button
            v-if="CAN(['write_activations']) && allAcepted"
            class="contract__no-files-found"
            type="submit"
            variant="outline-primary"
            :loading="loading"
            :disabled="!CAN(['write_activations'])"
            @click="createContract"
            >
                Create contract
            </ui-button>
            <status-form title="Review file contract" description="You need to contract details of your account." :form="contractData" :loading-contract="loading" type-form="contract"/>
        </div>
    </div>
</template>

<script>
import StatusForm from '@/components/activations/StatusForm';
import UiFilesItem from '@/components/ui/files/FilesItem';
import EmptyState from '@/components/ui/EmptyState';
import UiButton from '@/components/ui/buttons/Button';
export default {
    name: 'ContractFiles',
    components: { StatusForm, UiFilesItem, EmptyState, UiButton },
    props: {
        status: {
            type: String,
            default: null,
            required: true,
        },
        contract: {
            type: Object,
            default: () => {},
            required: true,
        },
        allAcepted: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    data() {
        return {
            contractData: {},
            contractUrl: "",
            viewDocument: false,
            loading: false,
            availableCreateContract: false,
        };
    },
    mounted() {
        this.contractData = this.contract;
        if(this.contractData?.status === 'accepted' || this.contractData?.status === 'rejected' || this.contractData?.status === 'incomplete' || this.contractData?.status === 'in_review'){
            this.viewDocument = true;
            if(this.contractData?.id){
                this.getContract();
            }
        }
    },
    watch: {
        contractData: {
            handler(new_options) {
                this.contractData = new_options;
                
                if(new_options?.uri != '' && this.contractData?.status === 'accepted'){
                    this.getContract();
                }
            },
            immediate: true,
        },
    },
    methods: {
        async createContract(){
            try {
                this.loading = true;
                let { data } = await this.$axios.post(`/_/activations/${this.$route.query.id}/contract`);
                this.contractData = data.contract;
                let updateContract = {
                    contract: {
                        status: data.contract.status,
                        message: 'Contract created',
                    }
                }
                this.$store.commit('activations/updateGlobalStatus', updateContract);
                this.$toast.success('Contract created successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async updateContrat(data){
            this.contractData = data.contract;
        },
        async getContract(){
            try {
                if(this.contractData?.id){
                    let { data } = await this.$axios.post(`/_/activations/${this.contractData.id}/refresh-contract-url/${this.$route.query.id}`);
                    this.contractUrl = data.results;
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
    }
}
</script>

<style lang="scss">
.contract{
    &__no-files-found {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;  
    }
    &__selected-image {
        margin: 20px;
        padding: 20px 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #424242;
        border-radius: 10px;
        color: #555555;
    }
}
</style>
