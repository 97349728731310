<template>
    <div class="files-item__selected-image" @click="handleClick">
        <div v-if="showingFile">
            <img v-if="!file_error" :src="fileLink" :alt="fileName" class="files-item__img-file" @error="file_error = true" />
            <i v-else class="far fa-file-pdf fa-2x" style="margin-right: 0.85rem"></i>
        </div>
        <div v-else>
            <span class="fa-lg" :class="addFileIcon"></span>
        </div>
        <span class="files-item__text-file">{{ fileName }}</span>
        <a v-sanitize-href="fileLink" target="_blank" @click.stop>
            <i v-if="download" class="far fa-2xl" :class="iconView"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'FilesItem',
    props: {
        fileName: {
            type: String,
            default: 'file default',
            required: true,
        },
        fileLink: {
            type: String,
            default: '',
            required: true,
        },
        showingFile: {
            type: Boolean,
            default: false,
            required: false,
        },
        fileType: {
            type: String,
            default: 'pdf',
            required: false,
        },
        iconView: {
            type: String,
            default: 'fa-arrow-alt-circle-down',
            required: false,
        },
        download: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            file_error: false,
        };
    },
    computed: {
        addFileIcon() {
            const fileTypeMappings = {
                pdf: 'fas fa-file-pdf',
                word: 'fas fa-file-word',
                excel: 'fas fa-file-excel',
                powerpoint: 'fas fa-file-powerpoint',
                image: 'fas fa-file-image',
                link: 'fas fa-link',
            };
            const [mainType, subType] = this.fileType.toLowerCase().split('/');
            return fileTypeMappings[this.fileType.toLowerCase()] || fileTypeMappings[mainType] || fileTypeMappings[subType] || 'fas fa-file';
        },
    },
    methods: {
        handleClick() {
            if (this.fileLink) {
                window.open(this.fileLink, '_blank');
            }
        }
    }
}
</script>

<style lang="scss">
.files-item {
    &__selected-image {
        margin: 10px;
        padding: 20px 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #5a5a5a;
        border-radius: 10px;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        color: #555555;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }

        a {
            color: $ecart-primary;
        }

        a:hover {
            color: $ecart-primary-600;
        }
    }

    &__img-file {
        width: 50px;
        height: 50px;
        padding: 0;
        border-radius: 5px;
        object-fit: cover;
    }

    &__text-file {
        text-align: center;
        text-overflow: ellipsis;
        width: 70%;
        overflow: hidden;
        word-break: normal;
    }
}
</style>