<template>
    <div class="edit-profile">
        <router-link to="/activations" class="btn btn-light mb-3">
            <span class="fas fa-chevron-left mr-1"></span>
            Back
        </router-link>
        <div v-if="loadingActivationInfo"></div>
        <div v-else>
            <template v-if="activation">
                <h6 class="activation-info__type-verification">
                    <span v-if="!activation.type" class="badge bg-light text-dark">
                        Unassigned
                    </span>
                    <span v-else>
                        {{ activation.type }} 
                    </span>
                    <ui-status :status="activation.status"/>
                </h6>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="activation-info__number">
                            Activation #{{ activation.id }}
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 activation-info__right" v-if="CAN(['write_activations']) && activation.status !== 'accepted' && activation.status !== 'rejected'">
                        <ui-button
                                type="submit"
                                variant="primary"
                                class="activation-info__button"
                                @click="approveActivation()"
                        >
                            Approve activation
                        </ui-button>
                        <ui-button
                            type="submit"
                            variant="danger"
                            class="activation-info__button"
                            @click="$refs.rejectActivationModal.show(activation.id)"
                        >
                            Reject activation
                        </ui-button>
                        <reject-modal ref="rejectActivationModal" @rejectCompleted="setActivationUpdated"/>
                    </div>
                </div>
            </template>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="account" 
                            title="User information" 
                        >
                        <user-information :info="account" />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="about_yourself" 
                            title="About yourself" 
                            :status="about_yourself.status"
                        >
                            <about-your-self-form 
                                v-if="activation && about_yourself" 
                                :inputs=about_yourself.questions 
                                :disabled-inputs="disabled_about_your_self" 
                            />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="verification.individual" 
                            :title="activation.type == 'individual' ? 'Natural person' : 'Legal representative'" 
                            :status="individual || verification.individual.status"
                        >
                            <individual-form
                                :verification="verification.individual"
                                :type-verification-sumsub = "activation.type"
                            />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="verification && verification.documents" 
                            title="Documents" 
                            :status="documents || verification.documents.status"
                        >
                            <documents-form 
                                :verification="verification.documents"
                                :status="activation.status" 
                            />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="activation && activation.risk_document" 
                            title="Risk document" 
                            :status="riskDocument || activation.risk_document.status"
                        >
                            <risk-file 
                                :risk="activation.risk_document" 
                                :status="activation.risk_document.status" 
                            />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="verification && verification.business" 
                            title="Legal entity" 
                            :status="business || verification.business.status"
                        >
                            <legal-entity-form 
                                :verification="verification.business" 
                                :status="activation.status" 
                                :disabled-inputs="disabled_verification_business" 
                            />
                        </ui-card>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="activation && activation.payment_method" 
                            title="Bank data" 
                            :status="paymentMethod || payment_method.status"
                        >
                            <payment-method-form 
                                :payment-method="payment_method" 
                                :status="activation.status" 
                                :disabled-inputs="disabled" 
                            />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            v-if="activation && activation.contract" 
                            title="Contract" 
                            :status="contract || activation.contract.status"
                        >
                            <contract-files 
                                :contract="activation.contract" 
                                :status="activation.status"
                                :all-acepted="allStatusAccepted"
                            />
                        </ui-card>
                    </div>
                </div>
                <div>
                    <ui-card-skeleton v-if="loadingActivationInfo" />
                    <div v-else>
                        <ui-card 
                            title="Events" 
                            title-icon="fas fa-history"
                        >
                            <time-line :event-data="timeLine || time_line" />
                        </ui-card>
                    </div>
                </div>
                <div class="col-md-12">
                    <ui-card
                        title="Account comments"
                        description="To register a comment it is necessary to complete the following information."
                        v-if="activation && activation.account_id"
                    >
                        <comments :account-id="activation.account_id" :scope="'write_activations'" />
                    </ui-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentMethodForm from '@/components/activations/PaymentMethodForm.vue';
import AboutYourSelfForm from '@/components/activations/AboutYourSelfForm.vue';
import LegalEntityForm from '@/components/activations/LegalEntityForm.vue';
import IndividualForm from '@/components/activations/IndividualForm.vue';
import DocumentsForm from '@/components/activations/DocumentsForm.vue'
import UserInformation from '@/components/activations/UserInformation.vue';
import Comments from '@/components/accounts/Comments';
import TimeLine from '@/components/activations/TimeLine';
import ContractFiles from '@/components/activations/ContractFiles.vue';
import UiCard from '@/components/ui/Card.vue';
import UiCardSkeleton from '@/components/ui/skeleton/CardSkeleton.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import UiButton from '@/components/ui/buttons/Button';
import RejectModal from '@/components/activations/RejectModal.vue';
import RiskFile from '@/components/activations/RiskDocument.vue';

import { mapState } from 'vuex';

export default {
    components: {
        PaymentMethodForm,
        AboutYourSelfForm,
        LegalEntityForm,
        IndividualForm,
        DocumentsForm,
        UserInformation,
        Comments,
        ContractFiles,
        TimeLine,
        UiCard,
        UiCardSkeleton,
        UiStatus,
        UiButton,
        RejectModal,
        RiskFile
    },
    data() {
        return {
            activation: {},
            account: {},
            payment_method: {},
            about_yourself: {},
            verification: {},
            time_line: [],
            updating: false,
            loadingActivationInfo: false,
            disabled: ['business_name', 'name', 'bank', 'number'],
            disabled_about_your_self: [],
            disabled_verification_business: [],
            allStatusAccepted: false,
        };
    },
    async mounted() {
        await this.getActivation();
        this.dataDisabledInputs();
        this.allAccepted();
    },
    computed: {
        ...mapState('activations', {
            paymentMethod: state => state.globalStatus.paymentMethod,
            contract: state => state.globalStatus.contract,
            documents: state => state.globalStatus.documents,
            individual: state => state.globalStatus.individual,
            business: state => state.globalStatus.business,
            timeLine: state => state.timeLine,
            riskDocument: state => state.globalStatus.riskDocument,
        }),
    },
    watch: {
        paymentMethod: 'allAccepted',
        documents: 'allAccepted',
        riskDocument: 'allAccepted',
    },
    methods: {
        async getActivation() {
            try {
                this.loadingActivationInfo = true;
                const { data } = await this.$axios.get(`/_/activations/${this.$route.query.id}`);
                this.assignData(data);
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loadingActivationInfo = false;
            }
        },
        async approveActivation() {
            try {
            this.loading = true;
            const {data} = await this.$axios.put(`/_/activations/${this.activation.id}`, { status: 'accepted' });
            this.assignData(data);
            this.$toast.success('Update approve status succesfully.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loadingActivationInfo = false;
            }
        },
        dataDisabledInputs(){
            this.disabled_about_your_self = this.about_yourself.questions.map(question => question.type == 'url' ? (question.type = 'text', question.question) : question.question);
            this.disabled_verification_business = this.verification.business ? this.verification.business.information.map(information => information.id) : [];
        },
        setActivationUpdated(data) {
            try {
                this.loadingActivationInfo = true;
                this.assignData(data);
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loadingActivationInfo = false;
            }
        },
        assignData(data) {
            this.activation = data;
            this.account = data.account || this.account;
            this.payment_method = data.payment_method;
            this.about_yourself = data.about_yourself;
            this.verification = data.verification;
            this.time_line = data.timeline;
            this.risk_document = data.risk_document;
        },
        allAccepted() {
            const computedStatuses = {
                payment_method: this.paymentMethod,
            };

            if (this.activation && this.activation?.risk_document) {
                computedStatuses.risk_document = this.riskDocument;
            }

            if (this.activation?.type === 'individual') {
                computedStatuses.individual = this.individual;
                computedStatuses.documents = this.documents;
            } else {
                computedStatuses.business = this.business;
            }

            this.allStatusAccepted = Object.entries(computedStatuses).every(([key, value]) => {
                if (key === 'documents' || key === 'individual' || key === 'business') {
                    const status = value !== null ? value : (this.activation.verification[key] && this.activation.verification[key].status);
                    if (status !== 'accepted') {
                        return false;
                    }
                }else{
                    const status = value !== null ? value : (this.activation[key] && this.activation[key].status);
                    if (status !== 'accepted') {
                        return false;
                    }
                }
                return true;
            });
        }
    }
}
</script>

<style lang="scss">
.activation-info {
    &__number {
        font-size: 0.95rem;
        margin-bottom: 1rem;
    }
    &__button {
        font-size: 0.95rem;
        margin-bottom: 1rem;
        margin-right: 10px;
    }&__right {
        display: flex;
        justify-content: flex-end;
    }
    &__name {
        margin-bottom: 1rem;
    }
    &__type-verification {
        font-size: 1.1rem;
        text-transform: capitalize;
        color: $ecart-primary;
        margin-bottom: 1rem;
    }
}
</style>