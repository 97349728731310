<template>
    <div>
        <div v-if="riskDocument && riskDocument.url != ''">
            <ui-files-item :file-link="riskDocument.url" :fileName="riskDocument.name" file-type="pdf"/>
            <status-form v-if="riskDocument.status !== 'Created'" @updateStatus="updateRiskDocument" title="Review risk document" description="You need to risk document details of your account." :form="riskDocument" :loading-contract="loading" type-form="risk_document"/>
        </div>
        <div v-else class="text-end">
            <empty-state class="risk__no-files-found" text="No documents found" img="folder.svg" img-size="md"/>
            <input
            id="file"
            ref="inputFile"
            name="file"
            type="file"
            class="form-control custom-textarea"
            placeholder="Select your file"
            accept=".pdf"
            :class="[{ 'is-invalid': v$.file.$dirty && v$.file.$error, 'is-valid':  v$.file.$dirty && !v$.file.$error }]"
            :disabled="!CAN(['write_activations'])"
            @change="change"
            />

            <div v-if="v$.file.$dirty && v$.file.$error" class="invalid-feedback">
                {{ v$.file.$errors[0].$message }}
            </div>

            <ui-button
                v-if="file"
                type="submit"
                variant="primary"
                class="risk__upload-button"
                :loading="loading"
                :disabled="!CAN(['write_activations'])"
                @click="uploadRiskDocument"
                >
                    Upload
            </ui-button>
        </div>
    </div>
</template>

<script>
import StatusForm from '@/components/activations/StatusForm';
import UiFilesItem from '@/components/ui/files/FilesItem';
import EmptyState from '@/components/ui/EmptyState';
import UiButton from '@/components/ui/buttons/Button';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
    name: 'RiskDocument',
    components: { StatusForm, UiFilesItem, EmptyState, UiButton },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    props: {
        status: {
            type: String,
            default: null,
            required: true,
        },
        risk: {
            type: Object,
            default: () => {},
            required: true,
        }
    },
    data() {
        return {
            riskDocument: {},
            riskDocumentUrl: "",
            viewDocument: false,
            loading: false,
            file: null,
        };
    },
    validations() {
        return {
            file: {
                required: helpers.withMessage(
                    'File is required',
                    required
                ),
                isFileTypeValid: helpers.withMessage(
                    'Invalid file type',
                    (value) => !value || (value && ['application/pdf'].includes(value.type))
                ),
                isFileSizeValid: helpers.withMessage(
                    'File size exceeds limit',
                    (value) => !value || (value && value.size < 5 * 1024 * 1024)
                ),
            }
        };
    },
    mounted() {
        this.riskDocument = this.risk;
    },
    methods: {
        async uploadRiskDocument(){
            try {
                this.loading = true;
                await this.v$.$validate();
                if (this.v$.$error) {
                    return;
                }

                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('collection', `Activations`);
                formData.append('collection_id', this.$route.query.id);
                formData.append('path', 'activation');
                formData.append('input', 'file');

                const { data } = await this.$axios.post(`/_/files`, formData, { 'Content-Type': 'multipart/form-data' });
                const riskData = {
                    name: 'Risk document',
                    path: data.path
                }

                const verification = await this.$axios.post(`/_/activations/${this.$route.query.id}/risk-document`, riskData, { 'Content-Type': 'multipart/form-data' });
                this.riskDocument = verification.data.risk_document;
                this.$toast.success('Risk document uploaded successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async updateRiskDocument(data){
            this.riskDocument = data.risk_document;
        },
        change(event) {
            this.file = event.target.files[0]
        },
    }
}
</script>

<style lang="scss">
.risk{
    &__no-files-found {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;  
    }
    &__selected-image {
        margin: 20px;
        padding: 20px 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #424242;
        border-radius: 10px;
        color: #555555;
    }
    &__upload-button {
        margin-top: 1rem;
    }
}
</style>
