<template>
    <div class="row">
        <div v-for="(input, index) in inputs" :key="index">
            <div v-if="!isHidden(input)" class="col-md-12 mb-4">
                <ui-input
                    v-if="input.type == 'text'"
                    :id=input.question
                    :name=input.question
                    :label=input.question
                    :type=input.type
                    option-value="value"
                    :value="input.answer"
                    :field="input.options"
                    :disabled="isDisabled(input.question)"
                />
                <ui-select
                    v-if="input.type == 'select' && !input.option_value"
                    :id="input.question"
                    class="w-100 mb-3"
                    :name="input.question"
                    :label="input.question"
                    option-label="key"
                    option-value="value"
                    :value="input.answer"
                    :options="input.options"
                    :disabled="isDisabled(input.question)"
                />
                <ui-select
                    v-if="input.type == 'select' && input.option_value"
                    :id="input.question"
                    :name="input.question"
                    :label="input.question"
                    class="w-100"
                    :value="input.answer"
                    :disabled="isDisabled(input.question)"
                />   
                <ui-select-multiple
                    v-if="input.type == 'multiple'"
                    :id="input.question"
                    :name="input.question"
                    :label="input.question"
                    class="w-100"
                    taggable
                    option-label="key"
                    :options="input.options"
                    v-model:value="input.answer"
                    option-value="value"
                    :disabled="isDisabled(input.question)"
                />   
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import UiInput from '@/components/ui/Input.vue';
import UiSelect from '@/components/ui/Select';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple'

import admin_form_mixin from '@/mixins/admin-form.mixin';

export default {
    name: 'formAboutYourSelf',
    components: { UiInput, UiSelect, UiSelectMultiple },
    mixins: [admin_form_mixin],
    props: {
        defaultData: {
            type: Object,
            default: () => {},
            required: false,
        },
        inputs: {
            type: Array,
            default: () => [],
            required: false,
        },
        disabledInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
        hiddenInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
    },

    setup: () => ({ v$: useVuelidate() }),

    methods: {
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        isHidden(inputName) {
            return this.hiddenInputs.includes(inputName);
        },
    },
}
</script>

