<template>
     <div class="row align-items-center" v-if="paymentMethodOptions && paymentMethodOptions.length > 0">
        <div class="col-md-12">
            <p class="legal-entity__subtitle">
                <span class="fas fa-money-check-alt"></span>
                Bank information
            </p>
        </div>
        <template v-for="(field, fieldName) in paymentMethodOptions[0]">
            <div v-if="shouldShowField(fieldName)" :key="fieldName" class="col-md-6 mb-4">
            <ui-input
                :id="fieldName"
                :name="fieldName"
                :label="getlabel(fieldName)"
                type="text"
                :value="field"
                :disabled="isDisabled(fieldName)"
            />
            </div>
        </template>
        <div class="col-md-12">
            <p class="legal-entity__subtitle">
                <span class="fas fa-file"></span>
                Documents
            </p>
        </div>
        <div v-if="hasStatementOfAccount" class="col-md-6 mb-4">
            <ui-files-item 
                :file-name="getlabel(paymentMethodOptions[0].statement_of_account.name)" 
                :file-link="paymentMethodOptions[0].statement_of_account.path" 
                :file-type="paymentMethodOptions[0].statement_of_account.mime_type" 
            />
        </div>
        <div v-if="CAN(['write_activations'])">
            <status-form v-if="status !== 'accepted'" title="Review data payment method" description="You need to provide the bank details of your account." :form="paymentMethod" type-form="payment_method"/>
        </div>
    </div>
    <div class="py-4" v-else>
        <empty-state title="No payment method found..."/>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import UiInput from '@/components/ui/Input.vue';
import StatusForm from '@/components/activations/StatusForm';
import EmptyState from '@/components/ui/EmptyState';
import UiFilesItem from '@/components/ui/files/FilesItem';

export default {
    name: 'FormProfile',
    components: { UiInput, StatusForm, EmptyState, UiFilesItem },
    props: {
        disabledInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
        hiddenInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
        paymentMethod: {
            type: Object,
            default: () => {},
            required: false,
        },
        status: {
            type: String,
            default: null,
            required: true,
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            fieldName: '',
        };
    },
    computed: {
        hasStatementOfAccount() {
            return this.paymentMethodOptions[0]?.statement_of_account !== undefined;
        },
        paymentMethodOptions() {
            return this.paymentMethod.data ? this.paymentMethod.data.methods : [];
        },
    },
    methods: {
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        shouldShowField(fieldName) {
            const fieldsToShow = ['business_name', 'name', 'bank', 'number'];
            return this.paymentMethodOptions[0]?.[fieldName] != undefined && fieldsToShow.includes(fieldName);
        },
        getlabel(fieldName) {
            if (fieldName === 'name') {
                return 'Payment method alias';
            }
            return fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/_/g, ' ');
        },
  },
};
</script>